import React, { Component } from "react";
import { Button } from "@mui/material";
import { verifyEmail } from "../services/authentication";

export default class VerifyEmail extends Component {

  render() {
    return (
    <div className="container">
      <h1 className="text-center">Welcome To EquipX</h1>
      <h7 className="text-center d-block">To use our site, please click the button below to verify your email</h7><br/>
      <h7 className="text-center"><i>Once pressed, an email will be sent to your inbox.  Please find the email (it may be located in your spam folder) and click on the link.  A confirmation message will show up, at which point you may return to this site and reload the page to begin.</i></h7>
      <br/><br/><br/>
      <div className = "w-100 d-flex">
        <Button variant = {"contained"} onClick = {verifyEmail} className = "mx-auto">Verify Email</Button>
      </div>
    </div>
  )
  }

}
