import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "./authentication";

function PrivateRoute({component:Component, ...rest}) {
  return (
    <Route
    {...rest}
    render = {({...props}) => getUser()? <Component {...props}/>: <Redirect to="/signIn"/>}
  />)
}

export default PrivateRoute;
