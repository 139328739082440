import { auth } from "./firebase";
import { createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, getAuth, signOut, sendPasswordResetEmail, sendEmailVerification } from "firebase/auth";
import { createUserProfile } from "./database";
import { toast } from "react-toastify";
import React, { Component } from "react";
import { CircularProgress } from "@mui/material";


export async function verifyEmail(){
  const toastId = toast.loading("Sending email...");
  try {
    await sendEmailVerification(auth.currentUser)
    toast.update(toastId, { render: "Success!", autoClose: 3000, isLoading: false, type: "success"});
  } catch (e) {
    toast.update(toastId, { render: "An error occured.  Try checking your network connection.", isLoading: false, autoClose: 8000, type: "error"});
  }


}




export async function resetPassword(email) {
  const toastId = toast.loading("Sending email...");
  try {
    await sendPasswordResetEmail(auth, email);
    toast.update(toastId, { render: "Success!", autoClose: 3000, isLoading: false, type: "success"});
  } catch (e) {
    toast.update(toastId, { render: "An error occured.  Check to make sure you have an account under the provided email address.  Also, try checking your network connection.", isLoading: false, autoClose: 8000, type: "error"});
  }
}

export async function createUser(userObj) {
  const toastId = toast.loading("Creating Your Account...")
  let user;
  try {
    const { email, password, ...accountInfo} = userObj;
    accountInfo.email = email;
    const result = await createUserWithEmailAndPassword(auth, email, password);
    if (!result || !result.user) {
      toast.update(toastId, {render: "Error! Possible causes: password must be at least 7 characters, you may already have an account, or someone has been registered with your same zip code, name, and birthYear.", type: "error", isLoading: false, autoClose: 12000});
      return Promise.reject();
    }
    user = result.user;
    await createUserProfile(user.uid, accountInfo);
    toast.update(toastId, {render: "Success!", type: "success", isLoading: false, autoClose: 3000});
  } catch (e) {
    toast.update(toastId, {render: "Error! Possible causes: password must be at least 7 characters, you may already have an account, or someone has been registered with your same zip code, name, and birthYear.", type: "error", isLoading: false, autoClose: 12000});
    if (user) user.delete();
    return Promise.reject();
  }
}

export async function signIn(email, password) {
  const toastId = toast.loading("Signing You In...");
  try {
    const user = await signInWithEmailAndPassword(auth, email, password);
    if (!user) toast.update(toastId, {render: "There was an error signing you in.  Your email or password may be incorrecrt. Or, check your internet connection.", type: "error", isLoading: false, autoClose: 2000});
    toast.update(toastId, {render: "Success!", type: "success", isLoading: false, autoClose: 2000})
    return true;
  } catch (e) {
    toast.update(toastId, {render: "There was an error signing you in.  Your email or password may be incorrecrt. Or, check your internet connection.", type: "error", isLoading: false, autoClose: 2000});
    return false;
  }
}


let currentUser = null;

export function getUser() {
  return currentUser;
}


export function withAuth(App) {
  return (
    class WithAuth extends Component {

      state = {
        user: null
      }

      componentDidMount() {

        const firebaseUser = getAuth().currentUser;
        if (firebaseUser) {
          currentUser = firebaseUser;
          this.setState({ firebaseUser })
        }

        onAuthStateChanged(auth, (user) => {
          if (!user) {
            currentUser = false;
            this.setState({ user: false });
          }
          if (user) {
            currentUser = user;
            this.setState({ user });
          }
        });

      }

      render() {
        const { user } = this.state;

        if (user === null) return (
          <div className="container">
            <CircularProgress size={100} className = "d-block mx-auto mt-3"/>
          </div>
        )

        else {
          return <App/>
        }
      }
    }
  )
}

export async function signOutUser() {
  const toastId = toast.loading("Signing You Out...");
  try {
    await signOut(auth);
    window.location = "/signIn"
    toast.update(toastId, {render: "Success!", type: "success", isLoading: false, autoClose: 3000});
  } catch (e) {
    toast.update(toastId, {render: "An error occured signing you out.", type: "error", isLoading: false, autoClose: 3000});
  }
}
