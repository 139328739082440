import React, { Component } from "react";
import { Paper, TextField, Button } from "@mui/material";
import { getItem, updateItem, storeImage, deleteImage, deleteItem, updateMessages } from "../services/database";
import PostForm from "./postForm";
import _ from "lodash";
import { toast } from "react-toastify";
import BackArrow from "./shared/backArrow";

export default class Manage extends Component {

  state = {
    category: "",
    name: "",
    size: {},
    images: [],
    oldImages: [],
    dropOffDate: null,
    item: undefined,
    description: ""
  }


  async componentDidMount() {
    const { id } = this.props.match.params;
    const item = await getItem(id);
    if (!item) return false;
    const { name, size, images, dropOffDate, category, selectedCity, description } = item;
    const imageObj = images.map(image => {return {src: image}});
    // const dropOffDateFormatted = new Date(dropOffDate.seconds * 1000);
    // this.setState({category, item, name, size, images: imageObj, oldImages: images, dropOffDate: dropOffDateFormatted});
    this.setState({category, item, name, size, images: imageObj, oldImages: images, selectedCity, description });

  }



  render() {
    try {
    const { category, name, size, images, item, dropOffDate, selectedCity, description } = this.state;
    const { id } = this.props.match.params;

    const showUpdateForm = item? true: false;

    return (
      <div className="container">
        <PostForm
          category = {category}
          selectedCity = {selectedCity}
          name = {name}
          size = {size}
          dropOffDate = {dropOffDate}
          images = {images}
          show = {showUpdateForm}
          onChange = {(obj) => this.setState(obj)}
          onSubmit = {this.updateItem}
          showDeleteButton = {true}
          description = {description}
          onDelete = {this.deleteItem}
          returnToMarketPlace = {this.returnToMarketPlace}
          id = {id}
        />
        <br/>
      </div>
    )
  } catch (e) {
    return (
      <h2>An error occured :(.  We were hoping you wouldn't get to see this page.  But, now you are here, please go back to the previous page.  Or, just sign in and sign out again.  If the error persists, assume the site is down, and know that we are fixing it as you read this message :).  Don't hessiate to contact support if you have any further questions!  </h2>
    )
  }
  }

  returnToMarketPlace = async () => {
    const { id } = this.state.item;

    await updateItem(id, {recieverId: null, state: "pendingDropOff", pickUpDate: null});
  }

  deleteItem = async () => {
    const { oldImages, item } = this.state;
    const { id } = this.props.match.params;

    await deleteItem(id);

    for (let i in oldImages) {
        await deleteImage(oldImages[i]);
    }

    this.setState({
      category: "",
      item: undefined,
      id: "",
      name: "",
      size: {},
      images: [],
      oldImages: [],
      dropOffDate: null,
    });

    this.props.history.push("/dashboard")


  }

  updateItem = async () => {
    const {name, size, images, category, oldImages, dropOffDate, item, description} = this.state;
    const { id } = this.props.match.params;

    const imageURLs = await Promise.all(images.map(async image => {
      const url = image.file? await storeImage(image.file): image.src;
      return url;
    }));


    const updateObj = {category, name, size, images: imageURLs, dropOffDate, description};

    await updateItem(id, updateObj);

    if (images[0].file) {
      for (let i in oldImages) {
          await deleteImage(oldImages[i]);
      }
    };

    this.setState({ oldImages: imageURLs });

  }

}


// Alert the reciver that their item is no longer available

// const { recieverId } = item;
// if (recieverId) {
//   const message = `We regret to inform you that one of the items you requested as been removed by the owner from the marketplace so cannot be picked up.`;
//   const updateObj = {
//     message,
//     action: "add",
//     itemId: item.id
//   }
//   await updateMessages(recieverId, updateObj)
// }


// const {recieverId} = item;
// if (recieverId) {
//   if (dropOffDate.setHours(0,0,0,0) >= new Date(item.pickUpDate.seconds * 1000).setHours(0,0,0,0)) {
//     const message = `The Dropoff Date of ${name}, which has a size of ${Object.values(size)[0]} has changed, so you will not be able to pick it up at your selected date.  Please update your pick up date by clicking on the item board and pressing the change pick up date button.  Thank you for your understanding`;
//     const updateObj = {
//       message,
//       action: "add",
//       itemId: item.id
//     }
//     await updateMessages(recieverId, updateObj)
//   }
// }
