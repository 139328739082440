import React, { Component } from "react";

export default class Privacy extends Component {

  render() {
    return (
      <div>
      <p>
<b>PRIVACY POLICY
<br/><br/>
Last Updated: 22 December, 2022
</b><br/><br/>
This policy details how data about you is used when you access our websites
and services (together, "EquipX") or interact with us.
<br/><br/>
<b>1. Protecting your privacy<br/></b>
*	We take precautions to prevent unauthorized access to or misuse of data about you.<br/>
*	We do not run ads, other than the classifieds posted by our users.<br/>
*	We do not share your data with third parties for marketing purposes.<br/>
*	We do not engage in cross-marketing or link-referral programs.<br/>
*	We do not employ tracking devices for marketing purposes.<br/>
*	We do not send you unsolicited communications for marketing purposes.<br/>
*	We do not engage in affiliate marketing (and prohibit it on EquipX).<br/>
*	We do provide email proxy & relay services to reduce unwanted email.<br/>
*	Please review privacy policies of any third party sites linked to from EquipX.<br/>
*	We do not respond to "Do Not Track" signals.
<br/><br/>
<b>2. Data we collect, use and disclose</b><br/>
Below is a list of all the types of data we have collected in the last 12
months, where we got it, why we collected it and the categories of third
parties to whom we disclosed it. We do not sell your data to third parties.
<br/><br/>

<table>
  <tr>
    <th>Data Type</th>
    <th>Source</th>
    <th>Use</th>
    <th>Recipients</th>
  </tr>
  <tr>
    <td>First and last name</td>
    <td>User entry</td>
    <td>Facilitating transactions and personalizing use</td>
    <td>First name only to posted-gear transaction counterpart</td>
  </tr>
  <tr>
    <td>Email address</td>
    <td>User entry</td>
    <td>Account creation, user-to-user and EquipX-to-user communications</td>
    <td>Posted-gear transaction counterpart</td>
  </tr>
  <tr>
    <td>Photos and other data you voluntarily provide</td>
    <td>User entry</td>
    <td>Facilitating and personalizing your use</td>
    <td>Public website</td>
  </tr>
  <tr>
    <td>IP address</td>
    <td>User's browser</td>
    <td>Analytics</td>
    <td>No one</td>
  </tr>
</table>
<br/><br/>
We may share some or all of the above listed data in the following circumstances:<br/>
*	to respond to subpoenas, search warrants, court orders, or other legal process.<br/>
*	to protect the rights, property, or safety of EquipX users, EquipX, or the general public.<br/>
*	at your direction (e.g. if you authorize us to share data with other users).
<br/><br/>
<b>3. Data we store</b><br/>
*	We retain data as needed to facilitate and personalize your use of
EquipX, combat fraud/abuse and/or as required by law.<br/>
*	We make good faith efforts to store data securely, but can make no guarantees.<br/>
*	You may access and update certain data about you via your account login.
<br/><br/>
<b>4. California Users</b><br/>
To learn more about the California Consumer Privacy Act and how it applies
to you, please visit the <a href="https://oag.ca.gov/privacy/ccpa">California Attorney-General's website</a>
<br/>
<i>* Right to know:</i> You have the right to request that we disclose the data we
collect, use and disclose, and other information relating to data we collect about you.
<br/>
<i>* Right to delete:</i> You have the right to request the deletion of data that we
have collected from you, subject to certain exceptions.
<br/>
<i>* Right to non-discrimination:</i> You have the right not to receive
discriminatory treatment for exercising the rights listed above.
<br/><br/>You may submit a request to know or delete via admin@equipx.us.
<br/><br/>Only you, or someone you authorize to act on your behalf, may make a request
to know or delete your data. An authorized agent may make a request on your
behalf by providing written permission signed by you.
We will need to confirm your identity before processing your request by
asking you to log into your existing account (if you are a registered user)
or by asking you for additional information, such as a government issued ID,
to confirm your identity against information we have already collected.
<br/><br/>Registered users of EquipX must be over the age of 18. If you are under the
age of 18, you may request (via admin@equipx.us  )
and obtain removal of content or information publicly posted on EquipX.
Please note that removal does not ensure complete or comprehensive removal
of said content or information from the Internet.
<br/><br/>
<b>5. International Users</b><br/>
By accessing EquipX or providing us data, you agree we may use and disclose
data we collect as described here or as communicated to you, transmit it
outside your resident jurisdiction, and store it on servers in the United
States. For more information please contact us at admin@equipx.us.
<br/><br/>
<b>6. Contact</b><br/>
If you have any questions or concerns about EquipX's privacy policy and
practices please email admin@equipx.us.
<br/><br/>
</p>
</div>
    )
  }

}
