import React from "react";
import { withRouter } from "react-router-dom"
import podium from "../images/Podium.svg";
import medal from "../images/Medal.svg";
import handShake from "../images/Handshake.svg";
import money from "../images/Money.svg";
import calendar from "../images/Calendar.svg";
import draftKingsLogo from "../images/DFS_Soccer.webp";
import soccerPlayer from "../images/soccerPlayer.jpeg";
import "../bootstrapExtra.css"

function DraftKingsPage({history}) {
  return (
     <div class="row my-5">
        <div class="col-lg justify-content-center mb-5">
        <h2  className = "text-center w-100 mb-3">Receive up to $500 First-Time Deposit Bonus!</h2>
        <h2  className = "text-center w-100 mb-3 d-none">Earn Money with Us!</h2>

        <p style = {{width: "85%"}} className = "text-center mx-auto" >Sign-up, deposit, and play with DraftKings <b><u>using the link on this page</u></b></p>

        <p style = {{width: "85%"}} className = "text-center mx-auto" >EquipX will donate <b><u>$25 per sign-up</u></b> (from our affiliate revenue) to sports teams in underprivileged neighborhoods.</p>

        <p style = {{width: "85%"}} className = "text-center mx-auto" ><b>Let us finance and bring joy to underserved communities: an end-of-season team celebration, some sought-after new equipment, …</b></p>

        <p style = {{width: "85%"}} className = "text-center mx-auto d-none" >In addition to any DraftKings offer, we will send you <b>$25</b></p>

        <p style = {{width: "85%"}} className = "text-center mx-auto d-none" ><b>Let us finance and bring joy to you or your team: an end-of-season celebration, some sought-after new equipment, …</b></p>
        </div>
        <div class="col-lg mb-5">
          <h2 className = "text-center mb-5">Why Play Daily Fantasy Sports</h2>
          <div class = "row w-100">
            <img src={medal} class = "my-2 col" style={{width: "5em", height: "5em"}}/>
            <p class = "col">Don’t need to finish first to win</p>
          </div>
          <div class = "row w-100">
            <img src={podium} class = "my-2 col" style={{width: "5em", height: "5em"}}/>
            <p class = "col">Play against people of your skill level</p>
          </div>
          <div class = "row w-100">
            <img src={money} class = "my-2 col" style={{width: "5em", height: "5em"}}/>
            <p class = "col">Win big cash prizes in public tournaments</p>
          </div>
          <div class = "row w-100">
            <img src={handShake} class = "my-2 col" style={{width: "5em", height: "5em"}}/>
            <p class = "col">Play private contests against your friends</p>
          </div>
          <div class = "row w-100">
            <img src={calendar} class = "my-2 col" style={{width: "5em", height: "5em"}}/>
            <p class = "col">1-day games mean no commitment</p>
          </div>
        </div>
      <div class="col-lg">
        <h2 className="mb-3 text-center">Click Here To Sign Up!</h2>
        <div class = "mx-auto card card-cover text-white rounded-5 text-shadow-1 overflow-hidden d-flex flex-column justify-content-end addEffect" style = {{backgroundImage: `url(${draftKingsLogo}),url(${soccerPlayer})`, backgroundColor:"black", cursor: "pointer"}} onClick = {() => window.location.href = (" https://dkdfs.sng.link/Avkw3/6j81?_dl=https%3A%2F%2Fwww.draftkings.com%2Fgateway%3Fs%3D737929933%26wpcid%3D196602%26wpcn%3D500DB%26wpsrc%3DKing%2520Ventures%26wpcrid%3D%26wpscn%3D&pcid=196602")}>
          <h2 class = "text-center mb-3">Take This Thrilling Opportunity</h2>
        </div>
      </div>
    </div>
  )
}


export default withRouter(DraftKingsPage)
