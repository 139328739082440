import React, { Component } from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withRouter } from "react-router-dom";


class BackArrow extends Component{

  render() {

    return (
      <IconButton onClick = {this.goBack}>
        <ArrowBackIcon></ArrowBackIcon>
      </IconButton>
    )

  }

  goBack = () => {
    this.props.history.goBack();
  }


};

export default withRouter(BackArrow)
