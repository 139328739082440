import React, { Component } from "react";

export default class Safety extends Component {

  render() {
    return (
      <div>
      <p>
<b>PERSONAL SAFETY
<br/><br/>
Last Updated: 22 December, 2022</b>
<br/><br/>
The exchange of sports-gear may take place face-to-face between users.  As such,
EquipX is restricted to users over 18 years of age.  
Please take the same common sense precautions online as you would offline.
When meeting someone for the first time, please remember to:<br/>
*	Insist on a public meeting place like a cafe, bank, or shopping center<br/>
*	Do not meet in a secluded place, or invite strangers into your home<br/>
*	Tell a friend or family member where you're going<br/>
*	Take your cell phone along if you have one<br/>
*	Consider having a friend accompany you<br/>
*	Trust your instincts
<br/><br/>
For more information about personal safety online, please peruse these resources:<br/>
* <a href="http://www.staysafeonline.org/">staysafeonline.org</a><br/>
* <a href="http://www.onguardonline.gov/">onguardonline.org</a><br/>
* <a href="http://getsafeonline.org/">getsafeonline.org</a><br/>
* <a href="http://wiredsafety.org/">wiredsafety.org</a><br/>
<br/>
EquipX is a <b>FREE</b> sports gear exchange site. No monetary payment is expected
between users: <b>DO NOT</b> provide or accept payments by or to anyone.
Furthermore, do not transact with third parties.
<br/>
Users must comply with all applicable laws, the EquipX Terms and Conditions,
and all posted site rules; please notify us (admin@equipx.us) of anybody flouting these rules.
<br/><br/>
EquipX is intended as a <b>SPORTS GEAR</b> exchange site; below is a partial list
of prohibited goods, services, and content:<br/>
*	<b>any and all non-sports related items</b><br/>
*	weapons, firearms/guns and components (eg. BB/pellet, stun, and spear guns, etc)<br/>
*	ammunition, clips, cartridges, reloading materials, gunpowder, fireworks, explosives<br/>
*	recalled items<br/>
*	hazardous materials<br/>
*	unpackaged or adulterated food<br/>
*	false, misleading, deceptive, or fraudulent content<br/>
*	offensive, obscene, defamatory, threatening, or malicious postings<br/>
*	anyone's personal, identifying, confidential or proprietary information<br/>
*	stolen property<br/>
*	counterfeit, replica, or pirated items<br/>
*	tickets or gift cards that restrict transfer<br/>
*	miscategorized, overposted, cross-posted, or nonlocal content<br/>
*	postings offering, promoting, or linking to unsolicited products or services<br/>
*	any good, service, or content that violates the law or legal rights of others<br/>
<br/>
</p>
</div>
    )
  }

}
