import React, { Component } from "react";
import { Skeleton, Paper, ImageList, ImageListItem, Button, TextField, IconButton  } from '@mui/material';
import { getItem, updateItem, requestItem, getUserDb, sendEmail } from "../services/database";
import BackArrow from "./shared/backArrow";
import { getUser } from "../services/authentication";
import {toast} from "react-toastify";
import Dialog from "./shared/dialog";
import { DatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import qs from "qs";
import { arrayRemove, arrayUnion } from "firebase/firestore";

export default class Item extends Component {

  state = {
    item: undefined,
    showDialog: false,
    pickUpDate: null,
    action: "recieve"
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const item = await getItem(id);
    if (!item) return false;
    this.setState({item});
  }

  render() {
    try {
    const { item, showDialog, pickUpDate, action } = this.state;

    const user = getUser();

    if (!item) {
      return (
        <div className = "container">
          <BackArrow/>
          <Skeleton variant = "rectangular" height = {400} ></Skeleton>
        </div>
      )
    }

    const isReciever = user.uid === item.recieverId;

    const allowRequestPickUp = item.state === "pendingPickUp"? false: true;

    const { name, images, size, selectedCity } = item;

    const {dateType} = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    // const displayDate =
    // dateType === "dropOff"? (
    //   <span>Drop Off Date: {new Date(item.dropOffDate.seconds * 1000).toDateString()}</span>
    // ): dateType === "pickUp"? (
    //   <span>Pick Up Date:  {new Date(item.pickUpDate.seconds * 1000).toDateString()}</span>
    // ): (
    //   <span>Earliest Pick Up Date:{new Date(item.dropOffDate.seconds * 1000 + (7 * 24 * 60 * 60 * 1000)).toDateString()}</span>
    // )

    return (
      <div className="container">
        <BackArrow/>
        <Paper className = "p-4">
          <h1>{name}</h1>
          {Object.values(size).map(value => Object.entries(value)[0]).map(([name,value]) => <React.Fragment><span>{name}: {value}</span><br/></React.Fragment>)}
          city: { selectedCity }
          <br/><br/>
          <p>{item.description}</p>
          <div className = {`d-${isReciever? "block": "none"}`}>
            <Button variant = "contained" className = "d-none my-4" color = "primary" onClick = {() => this.setState({showDialog: true, action: "update"})}>Edit Date</Button>
            <Button variant = "contained" className = "d-block my-4" color = "error" onClick = {this.removeItemRequest}>I don't want it anymore!</Button>
          </div>
        </Paper><br/>
        <div className = "row">
          {images.map((image) => (
              <img
                src={`${image}`}
                srcSet={`${image}`}
                alt={item.title}
                loading="lazy"
                style = {{maxHeight: "200px", objectFit: "contain"}}
                className = "col"
              />
          ))}
        </div>
        <Button variant = "contained" className = {`my-3 d-${allowRequestPickUp === true ? "block": "none"}`} onClick = {() => getUser()? this.setState({showDialog: true, action: "recieve"}): toast.error("You must sign in to access this feature!")}>Send Email to Owner to Arrange Pick Up!</Button>
        <p className = {`my-3 d-${allowRequestPickUp === true ? "block": "none"}`}><i>(Click to send email to the item's owner expressing your interest; you will be bcc'd)</i></p>
        <Dialog
          open = {showDialog}
          onClose = {() => this.setState({showDialog: false})}
          content = {
            <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Pick Up Date"
                className = "m-2 d-block"
                shouldDisableDate = {this.disableDate}
                value={pickUpDate}
                onChange={(newValue) => {
                  this.setState({pickUpDate: newValue});
                }}
                renderInput={(params) => <TextField className = "m-3 d-block" {...params} />}
              />
            </LocalizationProvider>
            </React.Fragment>
          }
          fullWidth = { true }
          title = "Your preferred pick-up date (to be confirmed with owner)"
          buttons = {[{text: "Cancel", onClick: () => this.setState({showDialog: false})}, {text: "Send!", onClick: action === "update"? this.updatePickUpDate:this.requestItem}]}
        />
      </div>
    )
  } catch (e) {
    return (
      <h2>An error occured :(.  We were hoping you wouldn't get to see this page.  But, now you are here, please go back to the previous page.  Or, just sign in and sign out again.</h2>
    )
  }
  }

  removeItemRequest = async () => {
    const { id } = this.state.item;

    const { item } = this.state;
    const { uid } = getUser();
    const { ownerId } = item;

    const { email:recieverEmail, firstName:recieverFirstName, lastName:recieverLastName  } = await getUserDb(uid);
    const { email:ownerEmail, firstName:ownerFirstName } = await getUserDb(ownerId);


    await updateItem(id, {recieverId: null, state: "pendingDropOff", pickUpDate: null});

    await sendEmail({to: "admin@equipx.us", bcc: [ownerEmail], from: "admin@equipx.us", subject: "EquipX: Status Update", content: `
    Hi ${ownerFirstName},
    <br /> <br />
    ${recieverFirstName} ${recieverLastName} no longer requires your item <br />
    Item Link: https://equipx.us/marketplace/${id}
    <br /><br />
    The item has been returned to the public marketplace.
    <br /><br />
    Thank you very much,
    <br /><br />
    The EquipX Team
    `})

    await sendEmail({to: "admin@equipx.us", bcc: [recieverEmail], from: "admin@equipx.us", subject: "EquipX: Status Update", content: `
    Hi ${recieverFirstName},
    <br /> <br />
    For your records, the email below was sent to the owner of the item you no longer require (and the item returned to our marketplace)
    <br />
    -----------------
    <br />
    Hi,
    <br /> <br />
    ${recieverFirstName} ${recieverLastName} no longer requires your item <br />
    Item Link: https://equipx.us/marketplace/${id}
    <br /><br />
    Thank you very much,
    <br /><br />
    The EquipX Team
    `})

    this.props.history.push("/dashboard");

  }

  disableDate = date => {
    // return date.setHours(0,0,0,0) < new Date(this.state.item.dropOffDate.seconds * 1000 + (7 * 24 * 60 * 60 * 1000)).setHours(0,0,0,0) || date.getDay() === 6 || date < new Date() || date.getDay() === 0
    return false;
  }

  requestItem = async () => {
    const { id } = this.props.match.params;

    const { pickUpDate,  item } = this.state;

    const { uid } = getUser();
    const { ownerId } = item;

    const { email:recieverEmail, firstName:recieverFirstName, lastName:recieverLastName  } = await getUserDb(uid);
    const { email:ownerEmail, firstName:ownerFirstName } = await getUserDb(ownerId);

    if (!getUser()) {
      toast.error("You must sign in to access this feature!");
      return false;
    }

    if (pickUpDate === null || pickUpDate === "") {
      toast.error("Please fill in a valid date.  Thank you!")
      return false;
    }

    const obj = {
      state: "pendingPickUp",
      pickUpDate
    }

    await requestItem(id);

    await updateItem(id, obj);

    await sendEmail({to: "admin@equipx.us", bcc: [ownerEmail], from: "admin@equipx.us", subject: "EquipX: Someone wants your item", content: `
    Hi ${ownerFirstName},
    <br /> <br />
    ${recieverFirstName} ${recieverLastName} has requested your item on EquipX !! <br />
    Item Link: https://equipx.us/marketplace/${id}
    <br /><br />
    Email address: ${recieverEmail}.
    <br />
    ${recieverFirstName}'s suggested pick up date: ${new Date(pickUpDate).toDateString()}.
    <br /> <br />
    Please reach out to ${recieverFirstName} directly by email to confirm your physical address and pick up date and time (or suggest another more convenient to you).
    <br /><br />
    Thank you very much,
    <br /><br />
    The EquipX Team
    `})

    await sendEmail({to: "admin@equipx.us", bcc: [recieverEmail], from: "admin@equipx.us", subject: "EquipX: You have reserved an item", content: `
    Hi ${recieverFirstName},
    <br /> <br />
    For your records the email below was sent to the owner of your desired item letting them know of your interest.  They should contact you shortly.
    <br />
    ---------------
    <br />
    Hi,
    <br /><br />
    ${recieverFirstName} ${recieverLastName} has requested your item on EquipX !! <br />
    Item Link: https://equipx.us/marketplace/${id}
    <br /><br />
    Email address: ${recieverEmail}.
    <br />
    ${recieverFirstName}'s suggested pick up date: ${new Date(pickUpDate).toDateString()}.
    <br /> <br />
    Please reach out to ${recieverFirstName} directly by email to confirm your physical address and pick up date and time (or suggest another more convenient to you).
    <br /><br />
    Thank you very much,
    <br /><br />
    The EquipX Team
    `})

    this.setState({showDialog: false});

    this.props.history.push("/dashboard")

  }

  updatePickUpDate = async () => {
    const { id } = this.props.match.params;

    const { pickUpDate } = this.state;

    if (!getUser()) {
      toast.error("You must sign in to access this feature!");
      return false;
    }

    if (pickUpDate === null || pickUpDate === "") {
      toast.error("Please fill in a valid date.  Thank you!")
      return false;
    }

    await updateItem(id, { pickUpDate});

    this.setState({showDialog: false});
  }

}

    // {displayDate}
