import React, { Component } from "react";
import BasicFormInputs from "./basicFormInputs";
import { Button, Paper } from "@mui/material";


export default class MarketplaceFilter extends Component {

  render() {

    try {

    const { name, size, category, onChange, show, onSubmit, selectedCity } = this.props;

    const display = show? "block": "none";

    return (
      <Paper className = {`p-3 d-${display}`}>
        <BasicFormInputs
          selectedItem = {name}
          selectedSize = { size }
          selectedCategory = {category}
          selectedCity = {selectedCity}
          onChange = {(obj) => onChange(obj)}
        />
        <br/>
        <Button variant = "contained" className="m-3" onClick = {onSubmit} >Search</Button>
      </Paper>
    )

  } catch (e) {
    return (
      <div></div>
    )
  }

  }

}
