import React, { Component } from "react";
import { TextField, Button, FormGroup, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import { signIn, resetPassword } from '../services/authentication';
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export default class SignIn extends Component {

  state = {
    email: "",
    password: "",
    dialogOpen: false,
    resetPasswordEmail: ""
  }

  render() {

    const { email, password, dialogOpen, resetPasswordEmail } = this.state;

    return (
      <div className="container d-flex align-items-center flex-column">
        <h1>Sign In</h1>
        <FormGroup sx = {{width: {xs:"75%", sm:"50%"}}}>
          <TextField
            className = "d-block m-2"
            variant = "outlined"
            label = "email"
            value = {email}
            type = "email"
            onChange = {e => this.setState({email: e.target.value})}
            fullWidth
            />
          <TextField
            className = "d-block m-2"
            variant = "outlined"
            label = "password"
            value = {password}
            onChange = {e => this.setState({password: e.target.value})}
            type = "password"
            fullWidth
          />
          <Button
            className = "d-block m-2 w-100"
            variant = "contained"
            onClick = {this.login}
            >
              Login!
          </Button>
          <p className = "m-2" style = {{color: 'grey'}}>Don't have an account, <Link to = "/signUp" className = "text-center">Sign Up!</Link></p>
          <p className = "m-2" style = {{color: 'grey'}}>Forgot Your Password, <a href= "#" className = "text-center" onClick = {() => this.setState({dialogOpen: true})}>Reset Password!</a></p>
        </FormGroup>
        <Dialog
          open={dialogOpen}
          fullWidth = "md"
        >
        <DialogTitle>
          Reset Password
          <IconButton className = "float-end" onClick = {() => this.setState({dialogOpen: false})}><CloseIcon/></IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              className = "d-block m-2"
              variant = "outlined"
              label = "email"
              value = {resetPasswordEmail}
              onChange = {e => this.setState({resetPasswordEmail: e.target.value})}
              type = "email"
              fullWidth
            />
          </DialogContentText>
          <DialogActions>
            <Button
              variant = "contained"
              onClick = {() => resetPassword(this.state.resetPasswordEmail)}
            >
            Reset!
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      </div>
    )
  }

  login = async () => {
    const { email, password } = this.state;
    const success = await signIn(email, password);
    if (success) this.props.history.push("/dashboard")
  }

}
