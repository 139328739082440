import React, { Component } from "react";

export default class Carousel extends Component {

  render() {

    const { items } = this.props;

    return (
      <div id="carouselExampleIndicators" class="w-100 carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner" style = {{borderRadius: "10px"}} >
          {items.map((item, index) => (
            <div class={`carousel-item ${index === 0? "active":""}`}>
              <div className = "w-100 d-flex justify-content-center" style = {{height: "200px", backgroundColor: "rgba(0,0,0,0.2)"}}>
                {item.content}
              </div>
            </div>
          ))}
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    )
  }

}
