// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage  } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBl8gX2-GrdlHUhsL1Mt8meHDpkdMW4hF4",
  authDomain: "equipx-d48ef.firebaseapp.com",
  projectId: "equipx-d48ef",
  storageBucket: "equipx-d48ef.appspot.com",
  messagingSenderId: "1009491869096",
  appId: "1:1009491869096:web:62173e943a0dae3de6319d",
  measurementId: "${config.measurementId}"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeIi2oiAAAAAJTqIySfTP-jCamwRHNqHtgO7qc8'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

// appCheck.activate("6LeIi2oiAAAAAJTqIySfTP-jCamwRHNqHtgO7qc8");

const db = getFirestore();
const storage = getStorage();
const auth = getAuth();
const functions = getFunctions()

export {db};
export {storage}
export { auth }
export { functions }
export { appCheck }
