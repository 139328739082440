import React, { Component } from "react";
import { TextField, Button, FormGroup, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import { signIn, resetPassword } from '../services/authentication';
import { sendEmail } from "../services/database";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import sgMail from "@sendgrid/mail"
import { toast } from "react-toastify"

export default class Contact extends Component {

  state = {
    email: "",
    subject: "",
    message: ""
  }

  render() {

    const { email, subject, message } = this.state;

    return (
      <div className="container d-flex align-items-center flex-column">
        <h1>Contact Us!</h1>
        <FormGroup sx = {{width:"100%"}}>
          <TextField
            className = "d-block m-2"
            variant = "outlined"
            label = "Your Email"
            value = {email}
            type = "email"
            onChange = {e => this.setState({email: e.target.value})}
            fullWidth
            />
          <TextField
            className = "d-block m-2"
            variant = "outlined"
            label = "Subject"
            value = {subject}
            onChange = {e => this.setState({subject: e.target.value})}
            type = ""
            fullWidth
          />
          <textarea
            className = "d-block m-2 p-2"
            placeholder = "Message"
            value = {message}
            onChange = {e => this.setState({message: e.target.value})}
            fullWidth
          />
          <Button
            className = "d-block m-2 w-100"
            variant = "contained"
            onClick = {this.login}
            >
              Send
          </Button>
        </FormGroup>
      </div>
    )
  }

  login = async () => {
    const { subject, message, email } = this.state;
    const content = `email: ${email} <br/> message: ${message}`;
    await sendEmail({to: "admin@equipx.us", from: "admin@equipx.us", subject, content });
  }

}
