import React, { Component } from "react";
import { TextField, Select, MenuItem, InputLabel, FormControl, Autocomplete, IconButton, ImageList, ImageListItem, Button } from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// import selectFiles from "select-files";
import { storeImage, postItem, updateItem } from "../services/database";
import { toast } from "react-toastify";
import { itemOptions } from "../services/itemOptions";
import BasicFormInputs from "./basicFormInputs";
import BackArrow from "./shared/backArrow";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from '@mui/lab';
import heic2any from "heic2any";
// import { FilePicker } from 'react-file-picker'


export default class PostForm extends Component {

  fileInputRef = React.createRef();

  render() {

    try {

    const { show, formType, name, size, images, onChange, onSubmit, category, selectedCity, description, id } = this.props;

    if (show === false) return "";

    return (
      <React.Fragment>
        <BackArrow />
        <h1>{formType? formType.charAt(0).toUpperCase() + formType.slice(1) + " Form": "Item To Be Gifted"}</h1>
        <BasicFormInputs
          selectedCategory = { category }
          selectedItem = {name}
          selectedSize = {size}
          selectedCity = {selectedCity}
          onChange = {(obj) => onChange(obj)}
        />
        <input type="file" ref = {this.fileInputRef} onChange = {(e) => this.hanldleSelectFile(e)} className  = "d-none" multiple/>
        <Button className = "m-3" onClick = {this.fileDialog} variant = "contained" color = "success">Add Photo(s)</Button>
        <Button color = "error" variant = "contained" onClick = {this.deletePhotos}>Delete Photo(s)</Button>
        <h7 style = {{fontSize: "13px"}} className = "d-block ms-3"><i>Note: To insert multiple photos, select/add all at one time.</i></h7>
        <div className="row">
          {images.map(image => (
            <img src={image.src} alt="" className="col p-2" style = {{maxHeight: "250px", objectFit: "contain"}} width={140}/>
          ))}
        </div>
        <textarea placeHolder = "Description (max 200 characters)" name="" className = "m-2 p-2" id="" cols="50" rows="5" maxLength = {200} value = {description} onChange = {(e) => onChange({description: e.target.value})}></textarea><br/>
        <Button variant="contained" className = "m-4" onClick = {onSubmit}>Submit</Button>
        <Button variant="contained" className = {`d-${this.props.showDeleteButton? "inline": "none"} me-4`} color = "error" onClick = {this.props.onDelete}>Delete</Button>
        <Button variant="contained" className = {`d-${this.props.showDeleteButton? "inline": "none"}`} color = "secondary" onClick = {this.props.returnToMarketPlace} >Return To Marketplace</Button>
      </React.Fragment>
    )

  } catch (e) {
    return (
      <h2>An error occured :(.  We were hoping you wouldn't get to see this page.  But, now you are here, please go back to the previous page.  Or, just sign in and sign out again.  If the error persists, assume the site is down, and know that we are fixing it as you read this message :).  Don't hessiate to contact support if you have any further questions!  </h2>
    )
  }

  }

  // shouldDisableDate = date => {
  //   return date.getDay() === 6 || date < new Date() || date.getDay() === 0
  // }

  deletePhotos = (e) => {
    const { onChange } = this.props;
    onChange({images: []})
  }



  fileDialog = (e) => {
    e.preventDefault();
    this.fileInputRef.current.click();
  }

  hanldleSelectFile = async (event) => {
    const toastId = toast.loading("Loading Your files...");
    try {
      const files = event.target.files;
      const fileSrcs = [];
      for (let i in Object.values(files)) {
        const file = files[i].type.includes("heic") ?
          await heic2any({
            blob: files[i],
            quality: 0.5
          })
          : files[i];
        await new Promise(async function(resolve, reject) {
          const fileReader = new FileReader();
          const result = await fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            fileSrcs.push({file: file, src: fileReader.result});
            resolve();
          }
        });
      }
      toast.dismiss(toastId);
      this.props.onChange({images: fileSrcs});
    } catch (e) {
      toast.update(toastId, { render: "An error occured, one of your files are not supported.", isLoading: false, autoClose: 3000, type: "error"});
    }
  };

};


// <LocalizationProvider dateAdapter={AdapterDateFns}>
//   <DatePicker
//     label="Drop Off Date"
//     className = "m-2 d-block"
//     value={dropOffDate}
//     shouldDisableDate = {this.shouldDisableDate}
//     onChange={(newValue) => {
//       onChange({dropOffDate: newValue});
//     }}
//     renderInput={(params) => <TextField className = "m-3 d-block" {...params} />}
//   />
// </LocalizationProvider>
