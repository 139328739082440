import React, { Component } from "react";
import { Paper, TextField, Button } from "@mui/material";
import ItemCard from "./itemCard"
import { getUserByEmail, getItems, updateItem, getUserDb } from "../services/database";
import { toast } from "react-toastify";

export default class Admin extends Component {

  state = {
    email: "",
    posts: [],
    requests: [],
    userInfo: {}
  }

  render() {
    const { email, posts, requests, userInfo } = this.state;
    return (
      <div className="container">
        <h1>Admin Dashboard</h1>
        <div className = "m-4">
          <h1>Information</h1>
          <Paper className = "p-4">
            <TextField
              className = "d-block m-2"
              variant = "outlined"
              label = "email address"
              value = {email}
              onChange = {e => this.setState({email: e.target.value})}
              type = "email"
              fullWidth
            />
            <br/>
            <Button
              variant = "contained"
              className = "m-2"
              onClick = {this.getUserInfo}
            >
              Next
            </Button>
            {Object.values(userInfo).length !== 0? (
              <p className = "m-2">
              Full Name: {userInfo.name} <br/>
              Birth Year: {userInfo.birthYear} <br/>
              Zip Code: {userInfo.zipCode} <br/>
              </p>
            ): ""}
          </Paper>
        </div>
        <div class = "m-4">
        <h2>Posts</h2>
        <div className = "row mt-3 m-3">
        {posts.map(item => {
          try {
            return (
                <ItemCard
                  item = {item}
                  dateType = "dropOff"
                  className = "col"
                  dropOffButton
                  handleDropOff = {this.handleDropOff}
                  onClick = {() => this.props.history.push(`/manage/${item.id}`)}
                />
            );
          } catch (e) {
            toast.error("An error occured displaying one or more of your items.");
            return null;
          }
        })}
        </div>
        <h2>Requests</h2>
        <div className = "row mt-3 m-3">
        {requests.map(item => {
          try {
            return (
                <ItemCard
                  item = {item}
                  dateType = "pickUp"
                  className = "col"
                  pickUpButton
                  handlePickUp = {this.handlePickUp}
                  onClick = {() => this.props.history.push(`/marketplace/${item.id}?dateType=pickUp`)}
                />
            )
          } catch (e) {
            toast.error("An error occured displaying one or more of your items.");
            return null
          }
        })}
        </div>
        </div>
      </div>
    )
  }

  handleDropOff = async (itemId) => {
    await updateItem(itemId, { state: "droppedOff", ownerId: null});
  }

  handlePickUp = async (itemId) => {
    await updateItem(itemId, { state: "pickedUp", recieverId: null});
  }

  getUserInfo = async () => {
    const uid = await this.getUserUid();
    if (!uid) return false;

    const userInfo = await getUserDb(uid);
    let posts = await this.getPosts(uid);
    let requests = await this.getRequests(uid);

    this.setState({ posts: posts || [], requests: requests || [] , userInfo: userInfo || {}});
  }

  async getUserUid() {
    const { email } = this.state;
    return getUserByEmail(email);
  }


  async getPosts(uid) {
    const { items} = await getItems({ownerId: uid});
    return items;
  }

  async getRequests(uid) {
    const { items  } = await getItems({recieverId: uid});
    return items
  }


}
