import React, { Component } from "react";
import { TextField, Select, MenuItem, InputLabel, FormControl, Autocomplete, IconButton, ImageList, ImageListItem, Button } from "@mui/material";
import { itemOptions } from "../services/itemOptions";

export default class BasicFormInputs extends Component {

  render() {

    const { selectedItem, selectedSize, selectedCategory, selectedCity, onChange } = this.props;

    const categoryArray = itemOptions[selectedCategory] || [];
    const selectedItemObj = categoryArray.find(option => option.name === selectedItem) || {};
    const categories = Object.keys(itemOptions);
    const items = categoryArray.map(i => i.name);
    const sizeInputs = selectedItemObj.size;
    return (
      <div className = "w-100">
      <Autocomplete
        className="m-3"
        disablePortal
        id="item"
        options={categories}
        value = {selectedCategory}
        onChange = {(event, newValue) => {
          onChange({category: newValue, name: "", size: {}})
        }}
        sx={{ width: 300, maxWidth: "75%" }}
        renderInput={(params) => <TextField {...params} label="sport" />}
      />
      <Autocomplete
        disablePortal
        id="item"
        className = {`d-${items.length !== 0?"inline-block":"none"} mx-3`}
        options={items}
        value = {selectedItem}
        onChange = {(event, newValue) => {
          // const selectedItemObj = Object.values(itemOptions).find(item => item.name === newValue) || {};
          // const selectedSize = [];
          // if (selectedItemObj.size) {
          //   selectedItemObj.size.forEach(size => {
          //     selectedSize.push({[selectedSize[size.name]]: ""});
          //   })
          // }
          // onChange({name:newValue , size:selectedSize})
          onChange({name:newValue, size: {}})
        }}
        sx={{ width: 300, maxWidth: "75%" }}
        renderInput={(params) => <TextField {...params} label="gear" />}
      />
      {sizeInputs && sizeInputs.map((sizeInput, index) => (
        <Autocomplete
          className="m-3"
          disablePortal
          id={sizeInput.name}
          options={sizeInput.options}
          value = {(selectedSize[index] && Object.values(selectedSize[index])[0]) || ""}
          onChange = {(event, newValue) => {
            const sizeObj = {...selectedSize};
            if (newValue && newValue !== "") sizeObj[index] = {[sizeInput.name]: newValue};
            else delete sizeObj[index];
            onChange({size: sizeObj});
          }}
          sx={{ width: 300, maxWidth: "75%" }}
          renderInput={(params) => <TextField {...params} label={sizeInput.name} />}
        />
      ))}
      <Autocomplete
        className="m-3"
        disablePortal
        options={["Manhattan Beach", "Hermosa Beach", "Redondo Beach", "El Segundo", "Torrance", "Palos Verdes", "Hawthorne"]}
        value = {selectedCity|| ""}
        onChange = {(event, newValue) => {
          onChange({selectedCity: newValue});
        }}
        sx={{ width: 300, maxWidth: "75%" }}
        renderInput={(params) => <TextField {...params} label={"owner's location"} />}
      />
      </div>
    )

  }

}
