import logo from './logo.svg';
import './App.css';
import { useEffect } from "react"
import HomePage from "./pages/homePage";
import { Link, Route, Switch, Redirect } from "react-router-dom";
import React from "react";
import Navbar from "./components/navbar";
import Post from "./components/post";
import MarketPlace from "./components/marketplace";
import Manage  from "./components/manage";
import Item from "./components/item";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignIn from "./components/signIn";
import SignUp from "./components/signUp";
import { withAuth, getUser, signOutUser } from "./services/authentication";
import { getUserDb, updateProfile } from "./services/database";
import PrivateRoute from "./services/privateRoute";
import Dashboard from "./components/dashboard";
import Admin from "./components/admin";
import Contact from "./components/contact";
import VerifyEmail from "./components/verifyEmail";
import Privacy from "./pages/privacy";
import Safety from "./pages/safety";
import Terms from "./pages/terms";


function App() {

  useEffect(async () => {
    try {
      const { uid } = getUser();
      if (!uid) return true;
      const user = await getUserDb(uid);
      if(user && user.justCreated) {
        await updateProfile(uid, {justCreated: false});
        signOutUser()
      } else return true
    } catch (e) {
      console.log("App useeffect erorr", e)
    }
  }, []);

  try {

  const alwaysDisplayLinks = [
    {href: "/post", text: "Gift"},
    {href: "/marketplace", text: "Marketplace"},
    {href: "/contact", text: "Contact"}
  ]

  const preLoginLinks = [
    {href: "/signIn", text: "sign in"},
    {href: "/signUp", text: "sign up"},
    ...alwaysDisplayLinks
  ]

  const postLoginLinks = [
    ...alwaysDisplayLinks,
    {href: "/dashboard", text: "dashboard"},
    {function: () => signOutUser(), text: "sign out"}
  ]

  let loggedIn = getUser()? true: false;

  const emailVerified = getUser().emailVerified;


  return (
    <React.Fragment>
    <main>
      <Navbar title="EquipX" preLoginLinks = {preLoginLinks} postLoginLinks = {postLoginLinks} loggedIn = {loggedIn}/>
      {(loggedIn && !emailVerified)?(
        <VerifyEmail></VerifyEmail>
      ):(
        <Switch>
        <Route path = "/marketplace/:id" component = {Item}/>
      <Route path = "/admin" component = {Admin}/>
      <PrivateRoute path = "/manage/:id" component = {Manage}/>
      <PrivateRoute path="/post" component={Post}/>
      <PrivateRoute path = "/dashboard" component={Dashboard}/>
      <Route path="/signIn" component = {SignIn}/>
      <Route path = "/signUp" component = {SignUp}/>
      <Route path="/marketplace" component={MarketPlace}/>
      <Route path="/privacy" component = {Privacy}/>
      <Route path = "/terms" component = {Terms}/>
      <Route path="/safety" component={Safety}/>
      <Route path="/contact" component={Contact}/>
      <Route path="/" component={HomePage}/>
        </Switch>
      )}
      </main>
      <footer className = "mt-5 bg-dark w-100 p-3 text-light">
        <h3>EquipX</h3>
        <Link className = "text-white text-decoration-none d-inline me-2" to = "/contact">Contact</Link> |
        <Link className = "d-inline mx-2 text-white text-decoration-none" to = "/privacy">Privacy Policy</Link> |
        <Link className = "d-inline mx-2 text-white text-decoration-none" to = "/terms">Terms And Conditions</Link>|
        <Link className = "d-inline mx-2 text-white text-decoration-none" to = "/safety">Personal Safety</Link>

        {/*<h7 className = "">DFS Sign-Up Offer Disclaimer: Minimum $5 deposit. Eligibility restrictions apply.  See <a href="https://draftkings.com">draftkings.com</a> for details.</h7>*/}
        <h7 className = "d-none">Picture Credits</h7>
        <br/>
        <h6><b>2022 King Ventures LLC</b></h6>
      </footer>
      </React.Fragment>
  );
} catch (e) {
  return (
    <h2>An error occured :(.  We were hoping you wouldn't get to see this page.  But, now you are here, please go back to the previous page.  Or, just sign in and sign out again.  If the error persists, assume the site is down, and know that we are fixing it as you read this message :).  Don't hessiate to contact support if you have any further questions!  </h2>
  )
}
}

export default withAuth(App);
