import React, { Component } from "react";
import PaperSelector from "../components/shared/paperSelector";
import { TextField } from "@mui/material";
import PostForm from "./postForm";
import {toast} from "react-toastify";
import {itemOptions} from "../services/itemOptions";
import { postItem, storeImage } from "../services/database";
import { getUser } from "../services/authentication"


export default class Post extends Component {

  state = {
    category: "",
    name: "",
    size: {},
    // dropOffDate: null,
    images: [],
    selectedCity: "",
    description: ""
  }

  render() {

    try {

    const { category, name, size, images, selectedCity, description } = this.state;

    return (
      <div className="container">
        <PostForm
          category = { category }
          name = {name}
          size = {size}
          images = {images}
          onChange = {(obj) => this.setState(obj)}
          onSubmit = {this.postItem}
          selectedCity = {selectedCity}
          description = {description}
        />
      </div>
    )

  } catch (e) {
    return (
      <h2>An error occured :(.  We were hoping you wouldn't get to see this page.  But, now you are here, please go back to the previous page.  Or, just sign in and sign out again.  If the error persists, assume the site is down, and know that we are fixing it as you read this message :).  Don't hessiate to contact support if you have any further questions!  </h2>
    )
  }

  }

  postItem = async () => {

    const { size, images, category, name, selectedCity } = this.state;

    // const selectedItemObj = itemOptions[category].find(option => option.name === this.state.name) || {};

    if ((category !== "other" && name !== "other") && (Object.entries(this.state).some(([key, value]) => value === null)  || Object.entries(this.state).some(([key, value]) => value === "")  || Object.entries(this.state).some(([key, value]) => value instanceof Date? false: Object.keys(value).length === 0  && key !== "images")  || Object.values(size).some(sizeValue => sizeValue === ""))) {
      toast.error("One of the fields is not filled out.")
      return false;
    }

    const imageURLs = [];
    for (let i in images ) {
      const url = await storeImage(images[i].file);
      if (!url) return false;
      imageURLs.push(url);
    }

    const { uid } = getUser();

    const itemObj = {
      ...this.state,
      images: imageURLs,
      selectedCity
    }

    const result = await postItem(itemObj);

    if (!result) return false;

    this.props.history.push("/dashboard")

  }

}


// const options = [
//   {name: "For An Individual", function: () => this.setState({formType:"individual", showForm:true})},
//   {name: "For A Team", function: () => this.setState({formType:"team", showForm:true})}
// ]

    // <PaperSelector options={options}/>
    // Was Underneath the first div


    // state = {
    //   formType: false,
    //   showForm: false
    // }


    // state = {
    //   name: "",
    //   images: [],
    //   size: {},
    //   dropOffDate: null,
    //   category: {}
    // }
