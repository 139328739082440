import React, { Component } from "react";

export default class Terms extends Component {

  render() {
    return (
      <div><p>
<b>TERMS AND CONDITIONS
<br/><br/>
Last Updated: 22 December, 2022</b><br/>
<br/>
By accessing or otherwise interacting with our website, or any associated
content/postings (together, "EquipX"), you agree to these Terms and
Conditions ("TAC"). You acknowledge and agree EquipX is a private site owned
and operated by King Ventures LLC. If you are accessing or using EquipX on
behalf of a business, you represent and warrant to EquipX that you have
authority to accept the TAC on behalf of that business and that business
agrees to the TAC. If you do not agree to the TAC, you are not authorized to
use EquipX. We may modify the TAC at any time in our sole discretion. You
are responsible for periodically checking for changes and are bound by them
if you continue to use EquipX. Our privacy policy, and all other policies,
site rules, and agreements referenced below or on EquipX, are fully
incorporated into this TAC, and you agree to them as well.<br/>
<br/>
<b>LICENSE</b><br/>
If you agree to the TAC and (1) are of sufficient age and capacity
to use EquipX and be bound by the TAC, or (2) use EquipX on behalf of a
business, thereby binding that business to the TAC, we grant you a limited,
revocable, non-exclusive, non-assignable license to use EquipX in compliance
with the TAC; unlicensed use is unauthorized. You agree not to display,
"frame," make derivative works, distribute, license, or sell, content from
EquipX, excluding postings you create. You grant us a perpetual,
irrevocable, unlimited, worldwide, fully paid/sublicensable license to use,
copy, display, distribute, and make derivative works from content you post.<br/>
<br/>
<b>USE</b><br/>
Unless licensed by us in a separate written or electronic agreement,
you agree not to use or provide software or services that interact or
interoperate with EquipX, e.g. for downloading, uploading,
creating/accessing/using an account, posting, flagging, emailing, searching,
or mobile use. You agree not to copy/collect EquipX content via robots,
spiders, scripts, scrapers, crawlers, or any automated or manual equivalent
(e.g., by hand). Misleading, unsolicited, and/or unlawful
postings/communications/accounts are prohibited, as is buying or selling
accounts. You agree not to post content that is prohibited by any of
EquipX's policies or rules referenced above. You agree not to abuse EquipX's
flagging or reporting processes. You agree not to collect EquipX user
information or interfere with EquipX. You agree we may moderate EquipX
access/use in our sole discretion, e.g., by blocking, filtering,
re-categorizing, re-ranking, deleting, delaying, holding, omitting,
verifying, or terminating your access/license/account. You agree (1) not to
bypass said moderation, (2) we are not liable for moderating or not
moderating, and (3) nothing we say or do waives our right to moderate, or
not. Unless licensed by us in a separate written or electronic agreement,
you agree not to (i) copy, adapt, create derivative works of, decompile,
reverse engineer, translate, localize, port or modify any website code, or
any software used to provide EquipX, (ii) combine or integrate EquipX with
any software, technology, services, or materials not authorized by us, (iii)
circumvent any functionality that controls access to or otherwise protects
EquipX, or (iv) remove or alter any copyright, trademark or other
proprietary rights notices. You agree not to use EquipX in any manner or for
any purpose that infringes, misappropriates, or otherwise violates any
intellectual property right or other right of any person, or that violates
any applicable law.<br/>
<br/>
<b>LIQUIDATED DAMAGES</b><br/>
You agree that repeated violations of the USE section
will irreparably harm and entitle us to injunctive or equitable relief, in
addition to monetary damages.<br/>
<br/>
<b>DISCLAIMER & LIABILITY</b><br/>
To the full extent permitted by law, King Ventures
LLC, and its officers, directors, employees, agents, licensors, affiliates,
and successors in interest ("EquipX Entities") (1) make no promises,
warranties, or representations as to EquipX, including its completeness,
accuracy, availability, timeliness, propriety, security or reliability; (2)
provide EquipX on an "AS IS" and "AS AVAILABLE" basis and any risk of using
EquipX is assumed by you; (3) disclaim all warranties, express or implied,
including as to accuracy, merchantability, fitness for a particular purpose,
and non-infringement, and all warranties arising from course of dealing,
usage, or trade practice; and (4) disclaim any liability or responsibility
for acts, omissions, or conduct of you or any party in connection with
EquipX. EquipX Entities are NOT liable for any direct, indirect,
consequential, incidental, special, punitive, or other losses, including
lost profits, revenues, data, goodwill, etc., arising from or related to
EquipX, and in no event shall such liability exceed the amount you paid us
in the year preceding such loss. Some jurisdictions restrict or alter these
disclaimers and limits, so some may not apply to you.<br/>
<br/>
<b>CLAIMS & INDEMNITY</b><br/>
Any claim, cause of action, demand, or dispute arising
from or related to EquipX ("Claims") will be governed by the internal laws
of California, without regard to conflict of law provisions, except to the
extent governed by US federal law. Any Claims will be exclusively resolved
by courts in Los Angeles, CA (except we may seek preliminary or injunctive
relief anywhere). You agree to (1) submit to the personal jurisdiction of
courts in Los Angeles, CA; (2) indemnify and hold EquipX Entities harmless
from any Claims, losses, liability, or expenses (including attorneys' fees)
that arise from a third party and relate to your use of EquipX; and (3) be
liable and responsible for any Claims we may have against your officers,
directors, employees, agents, affiliates, or any other party, directly or
indirectly, paid, directed or controlled by you, or acting for your benefit.<br/>
<br/>
<b>MISCELLANEOUS</b><br/>
Unless you have entered into a separate written or electronic
agreement with us that expressly references the TAC, this is the exclusive
and entire agreement between us and you, and supersedes all prior and
contemporaneous understandings, agreements, representations, and warranties,
both written and oral. Users complying with prior written licenses may
access EquipX thereby until authorization is terminated. Our actions or
silence toward you or anyone else does not waive, modify, or limit the TAC
or our ability to enforce it. The USE, LIQUIDATED DAMAGES, and CLAIMS &
INDEMNITY sections survive termination of the TAC, and you will remain bound
by those sections. If a TAC term is unenforceable, it shall be limited to
the least extent possible and supplemented with a valid provision that best
embodies the intent of the parties. The English version of the TAC controls
over any translations.
<br/><br/>
</p>
</div>
    )
  }

}
