import React, { Component } from "react";
import PaperSelector from "../components/shared/paperSelector";
import equipxShield from "../images/equipxShield.png";
import homePageImage from "../images/sportsStadium.jpg";
import sportsBettingMoney from "../images/sportsBettingMoney.jpg";
import { PickUpSteps, DropOffSteps } from "../components/steps";
import { Button, Paper } from "@mui/material";
import DraftKingsPage from "../components/draftKingsPage";
import Carousel from "../components/shared/carousel";
import wetsuit from "../images/wetsuit.png";
import { Link } from "react-router-dom";
import map from "../images/map.png";
import communities from "../images/communities.png";
import individuals from "../images/individuals.png";

class HomePage extends Component {


  carouselItems = [
    {
      content: (
        <div className = "text-center d-flex flex-column justify-content-center align-items-center h-100 w-75 text-center" style = {{fontSize: "16px"}}>
          <span style = {{fontSize: "35px"}}>🥶￨🏄‍♂️</span>
          Enjoy‍ surfing but don't go out enough to warrant buying a 7mm wetsuit?
          <Button variant = "contained" className = "m-2" onClick = { () => this.props.history.push("/marketplace")}>Search EquipX</Button>
        </div>
      )
    },
    {
      content: (
        <div className = "text-center d-flex flex-column justify-content-center align-items-center h-100 w-75 text-center" style = {{fontSize: "16px"}}>
        <span style = {{fontSize: "35px"}}>🎿￨🧥￨🧤￨🚲</span>
        Children outgrowing skis and winter jackets faster than you can buy them?
        <Button variant = "contained" className = "m-2" onClick = { () => this.props.history.push("/marketplace")}>Search EquipX</Button>
        </div>
      )
    }
  ]


  render() {

    const options = [
      {name:"Gift", function: () => this.props.history.push("post")},
      {name:"Recieve", function:() => this.props.history.push("marketplace")}
    ]

    return (
        <div className = "100%">
        <div style ={{height:"100%", minHeight: "50vh", width:"100%", marginTop:"-1rem",backgroundImage: `url(${homePageImage})`, backgroundSize: "cover"}} className = "row ms-0 p-0 justify-content-center text-white">
          <div className = "col-xl-3 align-content-center justify-content-center my-auto">
              <img src={equipxShield} alt="" style={{height:"fit-content", width: "100%", maxHeight:"350px", objectFit: "contain"}} className = ""/>
          </div>
          <div className = "col-xl-7 justify-content-center d-flex flex-column">
            <h1 className = "mt-0 mb-0  text-center" style={{color:"#3ebede", fontWeight:"600", fontSize: 40}}>EquipX</h1>
            <h3 className = "w-100 text-center" style = {{ fontFamily: "Brush script MT", color:"#3ebede", fontWeight: "600", lineHeight: "25px"}}>The South Bay's Sports Equipment Exchange</h3>
            <h3 className = "w-100 text-center" style = {{ fontFamily: "Brush script MT", color:"#3ebede", fontWeight: "400", lineHeight: "20px", fontSize: "20px"}}>Manhattan Beach * Hermosa * Redondo * El Segundo * Torrance * Palos Verdes</h3><br/>
            <h2 className = "text-center" style={{fontSize: "25px"}}>GIFT equipment you've outgrown or is collecting dust <br/><br/> RECEIVE needed equipment via our free marketplace</h2>
            <div className = "d-flex justify-content-center mt-3">
             <Button variant = "contained" className = "m-3" onClick = {() => this.props.history.push("post")}>Gift</Button>
             <Button variant = "contained" className = "m-3" onClick = {() => this.props.history.push("marketplace")}>Marketplace</Button>
            </div>

          </div>
        </div>
        <hr className = "m-0"/>
        <div className="container d-block my-4">
          <h1 style = {{color:"#1976d2"}} className = "text-center w-100 mb-5">EquipX Serves the South Bay Community</h1>
          {/*<h2><b>1. Your Local Community</b></h2><br/>*/}
          <h5 className = "text">What if you could give your sports gear a new life by gifting it to local neighbors for their continued use ...</h5>
          <h6><i>That was our idea.  EquipX is our solution.</i></h6><br/>
          <Carousel items = {this.carouselItems}/>
          <br/><br/>
          <img src={individuals} alt="" className = "mx-auto d-block" style = {{height: "160px", maxWidth: "90vw", objectFit: "contain"}}/>
          <p className = "text-center w-100"><b>Have Gear?</b> Post information on your gear to our marketplace <br/>
          <b>Need Gear?</b> Search our marketplace and reserve your gear <br/>
          <b>Process:</b> We will send an email connecting both parties for you to individually arrange to meet/exchange
          </p>
          {/*<h2 className = "mb-4 mt-5"><b>2. Under-Equipped Teams</b></h2>
          <h5 className = "text">Many under-privileged communities can't organize a team and play for lack of essential gear.  Donate your team's end-of-season uniform (the whole team if possible 😃), cleats, pads, etc. so we can forward to deserving youths.  <b>Help make a difference to their experience.</b></h5>
          <h6><i>That was our idea.  EquipX is our solution.</i></h6><br/>
          <h5 className = "text"><b>Teams with spare equipment:</b> <Link to = "/contact">contact</Link> us directly to see how we can repurpose your gear.</h5>
          <h5 className = "text"><b>Teams in need:</b> <Link to = "/contact">contact</Link> us directly to see how we may help fulfill your needs.</h5> <br/>
          <img src={communities} alt="" className = "mx-auto d-block" style = {{height: "120px", maxWidth: "90vw", objectFit: "contain"}}/>
          <p className = "text-center w-100"><b>Teams:</b> Contact us if you have excess team gear, or need team gear<br/>
          <b>Logistics:</b>Take to / collect from our warehouse<br/>
          <i>(Out-of-town-teams ... we can aid delivering to you)</i>
          </p>*/}

        </div>
        <hr className = "m-0 d-none"/>
        <div className="container d-none my-4">
          <h1 style = {{color:"#1976d2"}} className = "text-center w-100 mb-3 d-none">Who We Serve</h1>


        </div>
        {/*<hr className="m-0"/>*/}
        <div className = "container">
          {/*<h1 className = "text-center mt-4" style = {{color:"#1976d2"}}>Play Daily Fantasy Sports with DraftKings</h1>*/}
          {/*<p style = {{fontSize: 15, color:"#1976d2"}} className = "text-center d-block">(optional)</p>*/}
          {/*<DraftKingsPage/>*/}
        </div>
        <div className="m-4 d-none">
          <h1 style = {{color: "#1976d2"}} className = "text-center">DropOff / PickUp Logistics</h1>
          <Paper className = "p-3">
            <p className = "m-2"><b>Location:</b> 1219 Morningside Drive, Manhattan Beach</p>
            <p className = "m-2"><b>Times:</b> 8am-3pm Mondays-Fridays</p>
            <p className = "m-2"><b>Pick-Ups:</b> Bring ID matching account information</p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.1213263561917!2d-118.41172278484096!3d33.88652903389222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b3ec6e911aeb%3A0x15a213f357ee9839!2s1219%20N%20Morningside%20Dr%2C%20Manhattan%20Beach%2C%20CA%2090266!5e0!3m2!1sen!2sus!4v1643573991384!5m2!1sen!2sus" height="450" style={{border:0, width: "100%"}} allowfullscreen={true} loading="lazy"></iframe>
          </Paper>
        </div>
        </div>
    )

  }
}

export default HomePage;

//           <a href="https://www.freepik.com/vectors/background" className = "m-3">Background vector created by ikatod - www.freepik.com</a>
