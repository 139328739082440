import React, { Component } from "react";
import { getItems, getUserDb, updateMessages } from "../services/database";
import ItemCard from "./itemCard";
import { Snackbar, IconButton, Alert, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getUser } from "../services/authentication";
import { toast } from "react-toastify"
import { Link } from "react-router-dom";


export default class Dashboard extends Component {

  state = {
    posts: [],
    requests: [],
    messages: [],
    userInformation: {}
  }

  async componentDidMount() {
    const { uid } = getUser();

    let posts = await this.getPosts();
    let requests = await this.getRequests();
    const userInformation = await getUserDb(uid);

    this.setState({ posts: posts || [], requests: requests || [], userInformation: userInformation || {} });
  }

  async getPosts() {
    const { uid } = getUser();
    const { items} = await getItems({ownerId: uid});
    return items;
  }

  async getRequests() {
    const { uid } = getUser();
    const { items  } = await getItems({recieverId: uid});
    return items
  }




  render() {

    try {



    const { posts, requests, userInformation } = this.state;

    const { firstName } = userInformation;

    return (
      <div className="container">
        <h1 className = "">{firstName? `${firstName}'s`: "Your"} Dashboard</h1>
        <div class = "m-4">
        <h2>Your Items In The Marketplace</h2>
        <Button variant = "contained" onClick = { () => this.props.history.push("/post")}>Gift An Item</Button>
        <div className = "row mt-3 m-3">
        {posts.map(item => {
          try {
            return (
              <ItemCard
                item = {item}
                dateType = "dropOff"
                className = "col"
                onClick = {() => this.props.history.push(`/manage/${item.id}`)}
              />
            );
          } catch (e) {
            toast.error("An error occured displaying one or more of your items.");
            return null;
          }
        })}
        </div>
        <h2>Items You Have Reserved</h2>
        <Button variant = "contained" onClick = { () => this.props.history.push("/marketplace")}>Search Our Marketplace Inventory</Button>
        <div className = "row mt-3 m-3">
        {requests.map(item => {
          try {
            return (
              <ItemCard
                item = {item}
                dateType = "pickUp"
                className = "col"
                onClick = {() => this.props.history.push(`/marketplace/${item.id}?dateType=pickUp`)}
              />
            )
          } catch (e) {
            toast.error("An error occured displaying one or more of your items.");
            return null
          }
        })}
        </div>
        </div>
      </div>
    )
  } catch (e) {
    return (
      <h2>An error occured :(.  We were hoping you wouldn't get to see this page.  But, now you are here, please go back to the previous page.  Or, just sign in and sign out again.  If the error persists, assume the site is down, and know that we are fixing it as you read this message :).  Don't hessiate to contact support if you have any further questions!  </h2>
    )
  }
  }

}


// // removeMesssage = async (message, index) => {
//   const { uid } = getUser();
//   await updateMessages(uid, {action: "delete", message});
//   const messages = [...this.state.messages];
//   messages.splice(index, 1);
//   this.setState({messages});
// }
//
// {messages.map((message, i) => (
//   <Alert severity = "info" key = {i} open = {true} onClose = {() => this.removeMesssage(message, i)} className = "m-3">{message.message}</Alert>
// ))}
