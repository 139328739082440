import React, { Component } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from "react-router-dom";
import { Menu, MenuItem} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';



function Navbar({title, preLoginLinks, postLoginLinks, loggedIn, history}) {
  let links = loggedIn? postLoginLinks: preLoginLinks;
  const collapse = useMediaQuery('(max-width:700px)');
  const [showMenu, setShowMenu] = React.useState(null);
  if (loggedIn === undefined) links = [];
  return collapse === false? (
    <Box sx={{ flexGrow: 1 }} class="mb-3">
      <AppBar position="static" color = "" >
        <Toolbar>
            <Typography variant="h6" component={Link} to = "/" style = {{textDecoration: "none", color: "primary"}} sx={{ flexGrow: 1 }}>
              { title }
            </Typography>
          {links.map(link => {
            return link.href? (<Button color="primary"><Link to={link.href} style={{color:"inherit", textDecoration:"none"}}>{link.text}</Link></Button>)
              : (<Button color="primary" onClick={link.function}>{link.text}</Button>)
          })}
        </Toolbar>
      </AppBar>
    </Box>
  ) : (
    <Box sx={{ flexGrow: 1 }} class="mb-3">
      <AppBar position="static" color = "" className = "border-bottom">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 , zIndex: "1000"}}
            onClick = {(event) => setShowMenu(event.currentTarget)}
          >
         <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          anchorEl={showMenu}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(showMenu)}
          onClose={() => setShowMenu(null)}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
           >
            {links.map((link) => (
              <MenuItem  onClick={() => setShowMenu(null)}>
                {
                  link.href? (<Button color="primary" className = "w-100"><Link to={link.href} className = "w-100 p-0 m-0 text-start" style={{color:"inherit", textDecoration:"none"}}>{link.text}</Link></Button>)
                    : (<Button color="primary" className = "w-100 text-start p-0 m-0" onClick={link.function}><span className = "w-100 text-start p-0 ms-2"> {link.text}</span></Button>)
                }
              </MenuItem>
             ))}
          </Menu>
          <Typography variant="h6" component={Link} to = "/" style = {{textDecoration: "none", color: "primary"}} sx={{ flexGrow: 1 }}>
            { title }
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}


export default Navbar;
