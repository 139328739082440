import { db, storage, functions } from "./firebase";
import { collection, addDoc, getDocs, query, where, limit, doc, getDoc, updateDoc, deleteDoc, setDoc, orderBy, arrayUnion, arrayRemove, startAfter } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject  } from "firebase/storage";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { flatten } from "flat";
import { getUser } from "./authentication";
import { httpsCallable } from "firebase/functions";

export async function postItem(itemObj) {
  const toastId = toast.loading("Adding Your Item To Our Inventory");
  try {
    const { uid } = getUser();
    const item = {...itemObj, state: "pendingDropOff", recieverId: null, ownerId: uid}
    const docRef =  await addDoc(collection(db,"inventory"), item)
    if (!docRef) toast.update(toastId, {render: "An error occured", isLoading: false, autoClose: 5000, type: "error"});
    toast.update(toastId, { render: "Success", isLoading: false, autoClose: 3000, type: "success"});
    return true
  } catch (e) {
    toast.update(toastId, {render: "An error occured", isLoading: false, autoClose: 5000, type: "error"});
    return false;
  }
}

export async function postItemLocation(id, itemObj) {
  const toastId = toast.loading("Adding Your Item To Our Inventory");
  try {
    const { uid } = getUser();
    const docRef =  await setDoc(doc(db,"users", id), itemObj)
    if (!docRef) toast.update(toastId, {render: "An error occured", isLoading: false, autoClose: 5000, type: "error"});
    toast.update(toastId, { render: "Success", isLoading: false, autoClose: 3000, type: "success"});
    return true
  } catch (e) {
    toast.update(toastId, {render: "An error occured", isLoading: false, autoClose: 5000, type: "error"});
    return false;
  }
}

export async function storeImage(image){
  const toastId = toast.loading("Uploading Your Images");
  try {
    const uuid = uuidv4();
    const imageRef = ref(storage, `itemImages/${uuid}`);
    await uploadBytes(imageRef, image);
    const url = await getDownloadURL(imageRef);
    if (!url) {
      toast.update(toastId, {render:"An error occured", isLoading: false, type: "error", autoClose: 3000});
      return Promise.Reject();
    }
    toast.update(toastId, {render:"Success", isLoading: false, type: "success", autoClose: 2000});
    return url;
  } catch (e) {
    toast.update(toastId, {render:"An error occured", isLoading: false, type: "error", autoClose: 3000});
    return Promise.Reject();
  }
}

export async function getItems(filters, {lastDoc = null, pageNumber = 0, pageSize = 30} = {}){
  const toastId = toast.loading("Loading Items");
  try {
    const filtersFormatted = Object.entries(flatten(filters, {safe: true, maxDepth: 2}))
      .filter(([key, value]) => (value !== "") && (value !== null) && (value !== undefined) && (Object.keys(value).length !== 0))
      .map(([key, value]) => {
        // if ( key === "size") return where(key, "array-contains-any", value);
        return (Array.isArray(value) !== true)? (where(key,"==",value)): (where(key, "in", value))
      })
    // const q = query(collection(db, "inventory"), ...filtersFormatted, orderBy("dropOffDate"), limit(pageSize));
    const q = query(collection(db, "inventory"), ...filtersFormatted, limit(pageSize));
    let paginationQ;
    if (pageNumber !== 0) {
      if (!lastDoc) {
        toast.dismiss(toastId);
        return {items: [], lastDoc: null};
      }
      // paginationQ = query(collection(db, "inventory"), ...filtersFormatted, orderBy("dropOffDate"), startAfter(lastDoc), limit(pageSize));
      paginationQ = query(collection(db, "inventory"), ...filtersFormatted, orderBy("category"), startAfter(lastDoc), limit(pageSize));
    }
    const result = await getDocs(paginationQ || q);
    if (!result) {
      toast.update(toastId,{render: "Couldn't fetch the items.  Try checking your internet connection.", type: "error", isLoading: false, autoClose: 4000});
      return false;
    }
    const items = [];
    result.forEach(item => {
      if (!item.data()) return null;
      const data = item.data()
      if (data.category) {items.push({...data, id: item.id})}
    });
    toast.dismiss(toastId);
    // return items.sort((a, b) => a.dropOffDate - b.dropOffDate);
    return {items, lastDoc: result.docs[result.docs.length-1]};
  } catch (e) {
    toast.update(toastId, {render: "Couldn't fetch the items.  Try checking your internet connection.", type: "error", isLoading: false, autoClose: 4000});
    return [];
  }

};


export async function getItem(id) {
  // const toastId = toast.loading("Loading Your Item...")
  try {
    const q = query(doc(db, "inventory", id));
    const item = await getDoc(q);
    if (!item.exists()) {
      // toast.update(toastId, {render: "An error occured.  Try checking your internet connection.", isLoading: false, autoClose: 3000, type: "error"});
      toast.error("An error occured.  Try checking your internet connection.");
      return false;
    }
    // toast.update(toastId, {render: "Success!", type: "success", isLoading: false, autoClose: 2000});
    const data =  item.data();
    return {...data, id: item.id}
  } catch (e) {
    // toast.update(toastId, {render: "An error occured.  Try checking your internet connection.", isLoading: false, autoClose: 3000, type: "error"});
    toast.error("An error occured.  Try checking your internet connection.")
  }

}

export async function updateItem(id, obj) {
  const toastId = toast.loading("Updating Your Item");
  try {
    const updateObj = {...obj};
    // if (obj.state === "pendingPickUp") updateObj.state = arrayUnion(obj.state);
    await updateDoc(doc(db, "inventory", id), updateObj);
    toast.update(toastId, {render: "Success!", type: "success", isLoading: false, autoClose: 3000});
    return true;
  } catch (e) {
    toast.update(toastId, {render: "An error occured updating your item.  Try checking your internet connection.", type: "error", isLoading: false, autoClose: 3000});
    return Promise.reject();
  };

}


export async function requestItem(id) {
  const toastId = toast.loading("Requesting your item");
  try {
    const request = httpsCallable(functions, "requestItem");
    const { data:result } = await request({ docId: id });
    if (!result.success) {
      toast.update(toastId, {render: "Your cannot request more than three items at once.  Try requesting this item after you have picked up another item.  Thank you for your understanding.", type: "error", isLoading: false, autoClose: 6000});
      return Promise.reject();
    }
    toast.update(toastId, {render: "Success!", type: "success", isLoading: false, autoClose: 2000});
    return true;
  } catch (e) {
    toast.update(toastId, {render: "Your cannot request more than three items at once.  Try requesting this item after you have picked up another item.  Thank you for your understanding.", type: "error", isLoading: false, autoClose: 6000});
    return Promise.reject();
  }
}

export async function deleteImage(url) {
  try {
    const reference = ref(storage, url);
    await deleteObject(reference);
    return true;
  } catch (e) {
    return false;
  }
}

export async function deleteItem(id) {
  const toastId = toast.loading("Deleting Your Item");
  try {
    const docRef = doc(db, "inventory", id);
    const document = await getDoc(docRef);
    if (!document.exists()) await Promise.reject();
    await deleteDoc(docRef);
    toast.update(toastId, { render: "Success!", autoClose: 3000, isLoading: false, type: "success"});
    return true;
  } catch(e) {
    toast.update(toastId, {render: "Your item has not been deleted due to an error.  Please check your internet, try again, and if this error persists, please contact support.", type: "error", isLoading: false, autoClose: 5000});
    return Promise.reject();
  }
}

export async function createUserProfile(id, data) {
  try {
    const extendedData = {...data, justCreated: true};
    // const createProfile = httpsCallable(functions, "createUserProfile");
    const { data:result } = await postItemLocation(id, extendedData);
    return true;
  } catch (e) {
    return Promise.reject()
  }
}


export async function sendEmail(data) {
  const toastId = toast.loading("Sending Us An Email...");
  try {
    const sendEmail = httpsCallable(functions, "sendEmail");
    const { data:result } = await sendEmail(data);
    if (!result.success) toast.update(toastId, { render: "An error occured!  Try checking your internet connection.", type: "error", isLoading: false, autoClose: 2000});
    else toast.update(toastId, { render: "Success!", type: "success", isLoading: false, autoClose: 2000});
  } catch (e) {
    toast.update(toastId, { render: "An error occured!  Try checking your internet connection.", type: "error", isLoading: false, autoClose: 2000});
  }
}


export async function getUserDb(id) {
  try {
    const request = await getDoc(doc(db, "users", id));
    const data = request.data();
    if (!data) toast.error("An error while fetching your account information.")
    return data;
  } catch (e) {
    toast.error("An error while fetching your account information.");
    return {}
  }
}

export async function updateProfile(id, obj) {
  try {
    await updateDoc(doc(db, "users", id), obj);
    return true;
  } catch (e) {
    return false;
  }
}

export async function getUserByEmail(email) {
  try {
    const q = query(collection(db, "users"), where("email", "==", email));
    const docs = await getDocs(q);
    if (!docs.docs[0]) await Promise.reject();
    return docs.docs[0].id;
  } catch (e) {
    toast.error("Uh Oh!  An error occured.  Try checking your internet connection.");
    return false;
  }
}

// export async function updateMessages(id, obj) {
//   try {
//     const { action, message, itemId } = obj;
//     const updateObj = action === "add"? {messages: arrayUnion({message, id: new Date(), itemId })}: {messages: arrayRemove(message)};
//     await updateDoc(doc(db, "users", id), updateObj);
//     return true;
//   } catch (e) {
//     return false;
//   }
// }
