import React, { Component } from "react";
import MarketPlaceFilter from "./marketplaceFilter";
import { Button, Skeleton, Pagination } from "@mui/material";
import ItemCard from "./itemCard";
import { toast } from "react-toastify";
import { getItems } from "../services/database";
import { itemOptions} from "../services/itemOptions";
import BackArrow from "./shared/backArrow";


export default class MarketPlace extends Component {

  state = {
    name: "",
    size: {},
    items: [],
    category: '',
    isLoading: true,
    pageNumber: 0,
    lastDoc: null,
    selectedCity: ""
  }

  async componentDidMount() {
    await this.handleSearch();
  }

  render() {
    try {
    const { name, size, items: itemsArray, isLoading, category, pageNumber, selectedCity} = this.state;
    const items = itemsArray[pageNumber-1] || [];

    return (
      <div className="container">
        <BackArrow/>
        <h1>Marketplace</h1>
        <h7 className = "mb-2 d-none"><i>Note: Items may only be reserved after owner has dropped off with EquipX</i></h7>
        <MarketPlaceFilter
          onChange = {async (obj) => {
            if (obj.category === null) this.setState({...obj, items: [], pageNumber: 0, lastDoc: null}, async () => {
              await this.handleSearch();
            });
            this.setState(obj);
          }}
          onSubmit = {() => this.setState({items: [], pageNumber: 0, lastDoc: null}, this.handleSearch)}
          name = {name}
          size = {size}
          category= {category}
          show = {true}
          selectedCity = {selectedCity}
        />
        <div className="row m-5">
          {isLoading? (
            [...Array(4)].map(() => (
              <Skeleton variant = "rectangular" height = {200} width = {140} className = "col m-1 p-0"></Skeleton>
            ))
          ): items.map(item => {
            try {
              return (
                <ItemCard
                item = { item }
                onClick = {() => this.props.history.push(`marketplace/${item.id}`)}
                dateType= "minPickUp"
                className = "col-auto"
                onlyDroppedOff = {true}
                />
              )
            } catch (e) {
              return null
            }
          })}
        </div>
        {/*
        <div className="m-3 d-flex justify-content-center">
          <Pagination
            count = {itemsArray.length === 0? 5: itemsArray.length+1}
            page = {pageNumber}
            color = "primary"
            onChange = {this.handlePagination}
          />
        </div>
        */}
      </div>
    )
  } catch (e) {
    return (
      <h2>An error occured :(.  We were hoping you wouldn't get to see this page.  But, now you are here, please go back to the previous page.  Or, just sign in and sign out again.  If the error persists, assume the site is down, and know that we are fixing it as you read this message :).  Don't hessiate to contact support if you have any further questions!  </h2>
    )
  }
  }

  handlePagination = async (event, newValue) => {
    const pageNumber = newValue;
    const { items:itemsArray } = this.state;
    if (itemsArray[pageNumber-1] !== undefined) {
      this.setState({pageNumber: newValue});
      return true;
    }
    else this.handleSearch();
  }


  handleSearch = async () => {
    const { items, lastDoc } = await this.getItems();
    if (!items) return false;
    if (items.length === 0) {
      toast.info("No results were returned from your query.  That was either the last page, or you need to alter your filters or check your internet connection.");
      this.setState({pageNumber: this.state.pageNumber-1})
    }
    const { pageNumber } = this.state;
    const itemsArray = [...this.state.items];
    itemsArray.push(items)
    this.setState({items: itemsArray, isLoading: false, lastDoc, pageNumber: pageNumber + 1});
  }

  async getItems() {
    const { name, size, category, pageNumber, lastDoc, selectedCity } = this.state;
    const filters = {category, name, size, state: ["pendingDropOff", "droppedOff"], selectedCity};
    // const result = await getItems(filters, {lastDoc, pageNumber, pageSize: 8});
    const result = await getItems(filters);
    return result;
  }

}
