import React, { Component } from "react";
import firebaseui from "firebaseui";
import { TextField, Button, FormGroup, InputAdornment, IconButton, Checkbox, FormControlLabel } from "@mui/material";
import { DatePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { createUser } from "../services/authentication";
import { toast } from "react-toastify";
import { createUserProfile } from "../services/database";
import { Link } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export default class SignUp extends Component {

  state = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    birthYear: "654455",
    zipCode: "90266",
    showPassword: false,
    minAge: false
  }

  inputs = {
    email: {label: "Email", type: "email"},
    firstName: {label: "First Name", type: "text"},
    lastName: {label: "Last Name", type: "text"},
    // birthYear: {label: "Birth Year", type: "year"},
    // zipCode: {label: "Zip Code", type: "text"},
    password: {label: "Password", type: "password"},
  }

  render() {

    const inputs = this.inputs;

    const { showPassword, minAge } = this.state;

    return (
      <div className="container d-flex align-items-center flex-column">
        <h1>Sign Up</h1>
        <h7 className = "mx-center">EquipX asks all users be at least 18 years of age, given equipment is exchanged in-person</h7>
        <FormGroup sx = {{width: {xs: "100%", sm: "75%"}}}>
          {Object.keys(inputs).map(input => inputs[input].type !== "year"?
          (inputs[input].type !== "password" ?
            (
              <TextField
                className = "d-block m-2"
                variant = "outlined"
                label = {inputs[input].label}
                type = {inputs[input].type}
                value = {this.state[input]}
                key = {input}
                id = {input}
                onChange = {e => this.setState({[input]: e.target.value})}
                fullWidth
              />
            ):
            (
            <TextField
              className = "d-block m-2"
              variant = "outlined"
              label = {inputs[input].label}
              type = {showPassword? "text": inputs[input].type}
              value = {this.state[input]}
              key = {input}
              id = {input}
              onChange = {e => this.setState({[input]: e.target.value})}
              InputProps = {{
                endAdornment: (
                  <InputAdornment position = "end">
                    <IconButton
                      onClick={() => {
                        const { showPassword } = this.state;
                        this.setState({showPassword: !showPassword});
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              fullWidth
              />
            )
          ):
            (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year']}
                  label={inputs[input].label}
                  value={this.state[input]}
                  onChange={(newValue) => {
                    this.setState({[input]: newValue})
                  }}
                  fullWidth
                  renderInput={(params) => <TextField {...params} className = "m-2" helperText={null} fullWidth />}
                />
            </LocalizationProvider>
            )
          )}
          <FormControlLabel className = "m-0 w-auto" control={<Checkbox defaultChecked onChange = {(e, newValue) => this.setState({minAge: newValue})} checked = {minAge}/>} label="I am at least 18 years old." />
          <Button
            className = "d-block m-2 w-100"
            variant = "contained"
            onClick = {() => this.signUp()}
            >
              Sign Up!
          </Button>
          <p className = "m-2" style = {{color: 'grey'}}>Already have an account, <Link to = "/signIn" className = "text-center">Sign In!</Link></p>
        </FormGroup>
      </div>
    )
  }

  signUp = async () => {
    const obj = {...this.state};

    delete obj.showPassword;

    const formIsNotVaid = Object.values(obj).some(value => (value === "") || (value === null) || (value===undefined || (value===false)));

    if (formIsNotVaid) {
      toast.error("The Form Is Not Completed.  Please complete the form before signing up.")
      return false;
    }

    // obj.birthYear = obj.birthYear.getFullYear();
    obj.name = obj.firstName + obj.lastName;

    await createUser(obj);

    this.props.history.push("/dashboard")
  }

}
