import React from "react";
import { Dialog as MuiDialog, DialogTitle, Button, DialogContent, DialogActions, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function Dialog({open, onClose, content, title, buttons, conditionalContent, ...props}) {
  return (
    <MuiDialog open={open} {...props}>
      <DialogTitle sx = {{display: "inline-block"}}>{title} <IconButton onClick = {onClose} className = "float-end"><CloseIcon></CloseIcon></IconButton></DialogTitle>
      <DialogContent>
        {content}
        {(conditionalContent && conditionalContent.show) === true? conditionalContent.content:""}
      </DialogContent>
      <DialogActions>
      {buttons? buttons.map(button => (
        <Button onClick={button.onClick}>
          {button.text}
        </Button>
      )): ""}
      </DialogActions>
    </MuiDialog>
  )
}
