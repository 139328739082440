import React from "react";
import { Card, CardMedia, CardContent, Typography, CardActionArea, Button } from "@mui/material";
import { withRouter } from "react-router";

function ItemCard({ item, onClick, dateType, onlyDroppedOff, pickUpButton, handlePickUp, dropOffButton, handleDropOff }) {

  try {
    const { size: sizeObj, id, name: title, state, category, selectedCity } = item;

    const imageSrc = item.images[0];

    // const displayDate =
    // dateType === "dropOff"? (
    //   <span>Drop Off Date: {new Date(item.dropOffDate.seconds * 1000).toDateString()}</span>
    // ): dateType === "pickUp"? (
    //   <span>Pick Up Date: <br/> {new Date(item.pickUpDate.seconds * 1000).toDateString()}</span>
    // ): (
    //   <span>Earliest Pick Up Date: <br/> {new Date(item.dropOffDate.seconds * 1000 + (7 * 24 * 60 * 60 * 1000)).toDateString()}</span>
    // )

    let disabled = false;
    // if (onlyDroppedOff) {
    //   disabled = state === "pendingDropOff"? true: false;
    // }

    return (
      <Card className="p-0 m-1" sx = {{maxWidth: "200px", ...(disabled? {opacity: 0.7}: {})}} key = {id}>
        <CardActionArea onClick = {disabled? undefined:onClick}>
          <CardMedia
            component="img"
            height="140"
            image={imageSrc}
            alt="item image"
          />
          <CardContent>
            <Typography gutterBottom variant="h7" component="div">
              {category || ""}
            </Typography>
            <Typography gutterBottom variant="h5" component="div">
              {title || ""}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {Object.values(sizeObj).map(value => Object.entries(value)[0]).map(([key, value]) => (
                <React.Fragment>
                  <span>{key}: {value}</span>
                  <br/>
                </React.Fragment>
              ))}
              <br/>
              owner's location: {selectedCity}
              <br/><br/>
            </Typography>
          </CardContent>
        </CardActionArea>
        {dropOffButton? (
          <Button
            variant = "contained"
            onClick = {() => handleDropOff(id)}
            className = "m-3 fixed-bottom"
          >
          Drop Off
          </Button>
        ): ""}
        {pickUpButton? (
          <Button
            variant = "contained"
            onClick = {() => handlePickUp(id)}
            className = "m-3"
          >
          Pick Up
          </Button>
        ): ""}
      </Card>
    );
  } catch (e) {
    return <div>error</div>
  }
}

export default withRouter(ItemCard);

  // {displayDate}

    // <p>{disabled?`This item has not yet been dropped off to us by the owner.  We expect it on ${new Date(item.dropOffDate.seconds * 1000).toDateString()}`:""}</p>
