const itemOptions = {
  baseball: [
    { name:"Gloves", size:[{name:"glove size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name:"Cleats", size: [{name:"cleat size", options:["Child 6", "Child 7", "Child 8", "Child 9", "Adult 6", "Adult 7", "Adult 8", "Adult 9", "Adult 10", "Adult 11", "Adult 12", "other"]}]},
    { name:"Bats", size:[{name: "bat length", options:["26 in", "27 in", "28 in", "29 in", "30 in", "31 in", "32 in", "33 in", "34 in", "other"]}]},
    { name: "other"}
  ],
  basketball: [
    { name:"Uniform", size:[{name: "uniform size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name: "other"}
  ],
  combatsports: [
    { name:"Gi", size:[{name: "gi size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name: "other"}
  ],
  cycling: [
    { name:"City Bike", size:[{name: "frame height", options: ["other", "13-15 inches", "15-16 inches", "16-17 inches", "17-19 inches", "19-21 inches", "21+ inches", "other"]}, {name: "wheel size", options: ["16 inch", "20 inch", "24 inch", "26 inch", "27 inch","27.5 inch", "29 inch", "other"]}]},
    { name:"Road Bike", size:[{name: "frame height", options: ["other", "13-15 inches", "15-16 inches", "16-17 inches", "17-19 inches", "19-21 inches", "21+ inches", "other"]}, {name: "wheel size", options: ["16 inch", "20 inch", "24 inch", "26 inch", "27 inch","27.5 inch", "29 inch", "other"]}]},
    { name:"Mountain Bike", size:[{name: "frame height", options: ["other", "13-15 inches", "15-16 inches", "16-17 inches", "17-19 inches", "19-21 inches", "21+ inches", "other"]}, {name: "wheel size", options: ["16 inch", "20 inch", "24 inch", "26 inch", "27 inch","27.5 inch", "29 inch", "other"]}]},
    { name:"Electric Bike", size:[{name: "frame height", options: ["other", "13-15 inches", "15-16 inches", "16-17 inches", "17-19 inches", "19-21 inches", "21+ inches", "other"]}, {name: "wheel size", options: ["16 inch", "20 inch", "24 inch", "26 inch", "27 inch","27.5 inch", "29 inch", "other"]}]},
    { name: "other"}
  ],
  dance: [
    { name:"Performance Costume", size:[{name: "costume size", options: ["Age 4-6", "Age 6-8", "Age 8-10", "Age 10-12", "Age 12-14", "Age 14-16", "other"]}]},
    { name: "other"}
  ],
  football: [
    { name:"Helmets", size:[{name:"cleat size", options:["Child 6", "Child 7", "Child 8", "Child 9", "Adult 6", "Adult 7", "Adult 8", "Adult 9", "Adult 10", "Adult 11", "Adult 12", "other"]}]},
    { name:"Shoulder Pads", size:[{name:"age", options:["Child 7-9", "Child 9-11", "Child 11-13", "Youth 13-15", "Youth 15-17", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name:"Uniform", size:[{name: "uniform size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name: "other"}
  ],
  soccer: [
    { name:"Cleats", size:[{name:"cleat size", options:["Child 6", "Child 7", "Child 8", "Child 9", "Adult 6", "Adult 7", "Adult 8", "Adult 9", "Adult 10", "Adult 11", "Adult 12", "other"]}]},
    { name:"Uniform", size:[{name: "uniform size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name: "other"}
  ],
  snowsports: [
    { name:"Skis", size:[{name:"ski length", options:["110-120cm", "120-130cm", "130-140cm", "140-150cm", "150-160cm", "160-170cm", "170-180cm", "180-190cm", "other"]}]},
    { name:"Ski Boots", size:[{name: "boot size", options: ["22-22.5", "23-23.5", ,"24-24.5", "25-25.5", "26-26.5", "27-27.5", "28-28.5", "29-29.5", "30-30.5", "31-31.5", "32-32.5", "other"]}]},
    { name:"Snowboards", size:[{name: "board length", options: ["135-140cm", "140-145cm", "145-150cm", "150-155cm", "155-160cm", "160-165cm", "165-170cm", "other"]}]},
    { name:"Snowboard Boots", size:[{name: "boot size", options: ["22-22.5", "23-23.5", ,"24-24.5", "25-25.5", "26-26.5", "27-27.5", "28-28.5", "29-29.5", "30-30.5", "31-31.5", "32-32.5", "other"]}]}  ,
    { name:"Jackets", size:[{name: "jacket size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name:"Pants", size:[{name: "pants size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name:"Gloves", size:[{name: "glove size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name: "other"}
  ],
  watersports: [
    { name:"Surfboards", size:[{name:"board length", options:["4'6-5'0", "5'0-5'6", "5'6-6'0", "6'0-6'6", "6'6-7'0", "7'0-8'0", "8'0-9'0", ">9'", "other"]}]},
    { name:"Wetsuits", size:[{name: "wetsuit size", options: ["Youth XS", "Youth S", "Youth M", "Youth L", "Adult XS", "Adult S", "Adult M", "Adult L", "Adult XL", "other"]}]},
    { name: "other"}
  ],
  other : [

  ]
}

export { itemOptions };

// cityBike:{category: "Bikes", name: "City Bike", size:[{name: "Bike Height", options: ["13-15 inches", "15-16 inches","16-17 inches","17-29 inches", "19-21 inches", "21+ inches"]}, {name: "Wheel Size", options: ["16 inch", "20 inch", "24 inch", "26 inch", "27 inch","27.5 inch", "29 inch", "32 inch"]}]},
